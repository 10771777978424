@import './theme';

html,
body {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

* {
  --color-primary: #185a7d;
  --color-accent: #D7501C;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: "Regular";
  src: url("../src/assets/font/GothamNarrow-Book.otf");
  font-display: swap;
}

@font-face {
  font-family: "Medium";
  src: url("../src/assets/font/GothamNarrow-Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "Bold";
  src: url("../src/assets/font/GothamNarrow-Bold.otf");
  font-display: swap;
}

body {
  margin: 0;
  font-family: Regular, Roboto, "Helvetica Neue", sans-serif;
  background-color: #ffffff;
  scroll-behavior: smooth;
  position: relative;
  max-width: 100%;
}

button {
  //letter-spacing: 0.05rem;
}

.basic-dialog-container .mat-dialog-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

::-moz-selection {
  background: var(--color-accent);
  color: white;
}

::selection {
  background: var(--color-accent);
  color: white;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.undraggable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.gray {
  background-color: #f8f9fa;
}

:root {
  --toolbar-height: 65px;
}

.grecaptcha-badge {
  visibility: hidden;
}
